<template>
  <div id="page-templates">
    <h5 class="m-4">{{$t('TEMPLATE.TEMPLATES')}}</h5>

    <ErrorPopup
      :error="error_popup_message"
    />
    
    <InstancesSelectableTableModal
      ref="instancesModal"
      @confirm="onConfirmedTHSInstances"
    />

    
    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('TEMPLATE.CONFIRM_DELETE')"
      ref="deleteTemplateConfirm"
      type="success"
      @confirm="onDeleteTemplateConfirm"
    />
    
    
    <v-tabs background-color="white" color="accent-4" left>
      <v-tab>{{$t('TEMPLATE.TEMPLATES')}}</v-tab>
      <v-tab>{{$t('COMMON.SETTINGS')}}</v-tab>

      <v-tab-item>
        
        <TemplatesTable
          :items="templates"
          :currentCompanyId="currentCompanyId"
          @createTemplateClicked="createTemplate"
          @copyTemplateClicked="copyTemplate"
          @selectTemplateClicked="selectTemplate"
          @deleteTemplateClicked="deleteTemplateClicked"
          @changeSystem="changeSystem"
        />
        <div class="scroll-to-container"></div>

        <TemplateEditorModal 
          ref="template-editor-modal"
          :item="template"
          @updated="updateTemplate"
          @created="createdTemplate"
        />
        
      </v-tab-item>


      <v-tab-item>
        <SendoutTemplateComponent />
      </v-tab-item>
    </v-tabs>


    
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";


.modal-xl {
  max-width: 100% !important;
  padding-right: 17px;
}

:deep .dark-modal {
  color: white;
  background-color: #293039;
  border-bottom: 1px solid #444c56;
}

:deep .dark-modal-body {
  color: white;
  background-color: #293039;
}

</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';

import TemplatesTable from '@/view/pages/ml/templates/TemplatesTable.vue';
import TemplateEdit from '@/view/pages/ml/templates/TemplateEdit.vue';
import InstancesSelectableTableModal from '@/view/pages/ml/ths/instances/InstancesSelectableTableModal.vue';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import TemplateEditorModal from './templates/TemplateEditorModal.vue';
import SendoutTemplateComponent from '@/view/pages/ml/sendout_template/SendoutTemplateComponent.vue';

import ErrorPopup from '@/view/components/ErrorPopup.vue';

export default {
  name: 'templates',
  mixins: [ toasts ],
  components: {
    TemplatesTable,
    TemplateEditorModal,
    Confirm,
    InstancesSelectableTableModal,
    TemplateEdit,
    SendoutTemplateComponent,
    ErrorPopup
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'isTHS']),
    validateFromId() {
      if (this.form.from_id === null || this.form.from_id === undefined || this.form.from_id.length === 0) {
        return null;
      }

      if (this.form.from_id.length > 11) {
        this.isValid = false;
        return false;
      }

      const re = /[^a-z\d]/i;

      const test = !re.test(String(this.form.from_id).toLowerCase());
      this.isValid = test;
      return test;
    },
    fullscreen_visible() {
      const display = this.fullscreen ? 'block' : 'none';
      return `display:${display}`;
    },
    templateDesign() {
      return this.form.edit_html;
    },
  },
  mounted() {
    // temporary fix on third party
    //this.error_popup_message = 'ERR_TEMPLATES_UNAVAILABLE';
    //this.$nextTick(()=>{ this.error_popup_message = null; });
    this.loadTemplates();
  },
  data() {
    return {
      error_popup_message: null,
      template: null,
      isValid: true,
      
      stickprov: false,
      sms: false,
      email: false,

      moving: false,
      templates: [],
      fullscreen: false,
      sendout_id: null
    };
  },
  watch: {
    'template'() {
      const index = this.templates.findIndex(item => item.template_id === this.template.template_id);

      if (index >= 0 && !isNaN(parseInt(this.template.template_id+''))) {
        this.templates[index] = this.template;
        this.templates = [...this.templates];
      }
    },
    'form.is_sms'(newValue) {
      this.form.is_sms = newValue;

      if (this.form.is_sms) {
        this.form.from_name = '';
      }
    },

    'form.is_email'(newValue) {
      this.form.is_email = newValue;
    },

    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadTemplates();
      }
    }
  },
  methods: {

    createdTemplate(template) {
      this.templates.push(template);
    },
    updateTemplate(template) {
      this.template = { ...template };
    },

    onSyncTHSClicked() {
      this.$refs['instancesModal'].show();
    },

    onConfirmedTHSInstances(instances) {
      const ids = instances.map(item => (item.id));

      const loader = this.$loading.show();

      axios
        .post(`/template/ths_sync`, { template_id: this.form.template_id, ids: ids })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.SYNCED'))
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_SYNC'))
          }

          loader && loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_SYNC'));

          loader && loader.hide();
        });
    },


    createTemplate() {

      this.moving = false;
      this.resetForm();

      this.template = { ...this.template }

      this.$refs['template-editor-modal'].show();
    },
    copyTemplateToCompany(template_id, company_id) {
      const loader = this.$loading.show();

      axios
        .post(`/template/copy`, { template_id: template_id, company_id: company_id })
        .then(res => {
          loader && loader.hide();
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_COPY'));
        });
    },

    copyTemplate(template, movable = false) {
      const loader = this.$loading.show();

      if (!movable || template.company_id == this.currentCompanyId) {
        axios
          .post(`/template/copy`, { template_id: template.template_id })
          .then(res => {
            loader && loader.hide();
            this.templates.push(res.data);
            this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.COPIED'));
          })
          .catch(err => {
            loader && loader.hide();
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_COPY'));
          });
      } else {
        axios
          .get(`/template/${template.template_id}`)
          .then(res => {
            loader && loader.hide();
            this.form = res.data;
            this.template = res.data;
            this.moving = true;

            this.template = { ...this.template }
            
            this.$refs['template-editor-modal'].show();
          })
          .catch(err => {
            loader && loader.hide();
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
          });
      }
    },
    deleteTemplateClicked(template_id) {
      this.toDelete = template_id;
      this.$refs['deleteTemplateConfirm'].show();
    },
    onDeleteTemplateConfirm() {
      this.delete_template(this.toDelete);
      this.toDelete = null;
    },

    async delete_template(template_id) {
      try {
        const loader = this.$loading.show();

        const res = await axios.delete(`/template/${template_id}`);

        loader && loader.hide();

        if (res.status === 204)  {
          this.templates = this.templates.filter(item => item.template_id !== template_id);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.DELETED'));

          return;
        }
      }
      catch (err) {
        loader && loader.hide();

        console.error('delete_template error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_DELETE'));

    },
    changeSystem(template) {
      const loader = this.$loading.show();

      axios
        .put(`/template/${template.template_id}`, template)
        .then(res => {
          loader && loader.hide();
          this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.UPDATED'));
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_UPDATE'));
        });
    },

    selectTemplate(template_id) {
      const loader = this.$loading.show();
      axios
        .get(`/template/${template_id}?company_id=${this.currentCompanyId}`)
        .then(res => {
          loader && loader.hide();
          this.form = res.data;
          this.template = res.data;

          this.template = { ...this.template }

          this.form.edit_html = this.form.edit_html ? JSON.parse(this.form.edit_html) : null;

          this.$refs['template-editor-modal'].show();
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
        });
    },
    loadTemplates() {
      this.templates = [];
      const loader = this.$loading.show();
      axios
        .get(`/template?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.templates = res.data;

            loader.hide();
          }
        })
        .catch(err => {
          console.error(err);
          loader.hide();
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
        });
    },
    
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    plaintextChanged(text) {
      this.form.text = text;
    },
    saveDesign(design, html) {
      this.form.template = html;
      this.form.edit_html = design;
      // this.saveTemplate();
      this.toggleFullscreen();
    },
    backToSendout() {
      this.$router.push(`/ml-sendout/${this.sendout_id}`);
    },

    resetForm() {
      this.template = {
        template_id: '',
        reply_to: '',
        name: '',
        subject: '',
        type: [],
        content: '',
        template: '',
        edit_html: null,
        text: '',
        stickprov: false,
        from_email: null
      };
    },
    moveToCompanies() {
      const company_ids = this.$refs['companies_selected'].getCompanies();

      for (const cid of company_ids) {
        this.copyTemplateToCompany(this.form.template_id, cid);
      }

      this.toastr('success', this.$t('COMMON.OK'), this.$t('TEMPLATE.COPIED'));
    },

  }
};
</script>
